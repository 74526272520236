export default {
  colors: {
    primary: '#000000', // Predeterminado de la plantilla, por ejemplo: cuando nos posicionamos en un link.
    background: '#ffffff', // Fondo del sitio web.
    shape: `#ffcd99`,  // Aplica para las notas, sombreado del menú.
    title: `#101820`, // Títulos en la plantilla.
    text: `#000000`, // Textos en la plantilla.
    components: {
      blockquote: {
        background: `#ffcd99`,
        text: `#000000`,
      },
    },
  },
  fonts: {
    fontFace: {
      family: 'Segoe UI',
    },
    body: 'Segoe UI',
  },
};